import * as React from "react";

const LogoIcon = (props) => (
  <svg
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    width={props.width || "128px"}
    height={props.height || "128px"}
    viewBox="0 0 16 16"
    enableBackground="new 0 0 16 16"
    xmlSpace="preserve"
    role="img"
    {...props}
  >
    <g>
      <defs>
        <path
          id="SVGID_1_"
          d="M-91.159,76.361c0,12.593,4.796,24.063,12.666,32.691l25.78-80.155 C-74.672,33.536-91.159,53.021-91.159,76.361"
        />
      </defs>
      <clipPath id="SVGID_2_">
        <use xlinkHref="#SVGID_1_" overflow="visible" />
      </clipPath>
      <linearGradient
        id="SVGID_3_"
        gradientUnits="userSpaceOnUse"
        x1={-582.478}
        y1={478.9795}
        x2={-582.0389}
        y2={478.9795}
        gradientTransform="matrix(-60.6391 -130.041 -130.041 60.6391 26908.2539 -104691.5)"
      >
        <stop
          offset={0}
          style={{
            stopColor: "#FFCE2A",
          }}
        />
        <stop
          offset={0.9892}
          style={{
            stopColor: "#FF4FC2",
          }}
        />
        <stop
          offset={1}
          style={{
            stopColor: "#FF4FC2",
          }}
        />
      </linearGradient>
      <polygon
        clipPath="url(#SVGID_2_)"
        fill="url(#SVGID_3_)"
        points="-84.293,123.779 -121.86,43.213 -59.581,14.17 -22.011,94.735  "
      />
    </g>
    <g>
      <defs>
        <polygon
          id="SVGID_4_"
          points="-53.324,58.43 -41.635,58.43 -47.138,39.201  "
        />
      </defs>
      <clipPath id="SVGID_5_">
        <use xlinkHref="#SVGID_4_" overflow="visible" />
      </clipPath>
      <linearGradient
        id="SVGID_6_"
        gradientUnits="userSpaceOnUse"
        x1={-581.5093}
        y1={484.2109}
        x2={-581.0703}
        y2={484.2109}
        gradientTransform="matrix(-19.0129 -40.7732 -40.7732 19.0129 8643.9004 -32857.3125)"
      >
        <stop
          offset={0}
          style={{
            stopColor: "#FFCE2A",
          }}
        />
        <stop
          offset={0.9892}
          style={{
            stopColor: "#FF4FC2",
          }}
        />
        <stop
          offset={1}
          style={{
            stopColor: "#FF4FC2",
          }}
        />
      </linearGradient>
      <polygon
        clipPath="url(#SVGID_5_)"
        fill="url(#SVGID_6_)"
        points="-51.237,62.907 -60.689,42.634 -43.723,34.724 -34.269,54.994  "
      />
    </g>
    <g>
      <defs>
        <path
          id="SVGID_7_"
          d="M-28.801,71.891h-8.973l-1.449-5.047h-16.806l-15.514,48.355c2.008,1.494,4.133,2.755,6.36,3.927 l13.848-41.065h16.127l-2.665,8.415h-7.332l-8.334,24.682h15.179l25.049-73.633c-7.857-5.98-17.594-9.535-28.175-9.781 L-28.801,71.891z"
        />
      </defs>
      <clipPath id="SVGID_8_">
        <use xlinkHref="#SVGID_7_" overflow="visible" />
      </clipPath>
      <linearGradient
        id="SVGID_9_"
        gradientUnits="userSpaceOnUse"
        x1={-582.4912}
        y1={478.6895}
        x2={-582.0526}
        y2={478.6895}
        gradientTransform="matrix(-74.0379 -158.7747 -158.7747 74.0379 32849.9961 -127820.0078)"
      >
        <stop
          offset={0}
          style={{
            stopColor: "#FFCE2A",
          }}
        />
        <stop
          offset={0.9892}
          style={{
            stopColor: "#FF4FC2",
          }}
        />
        <stop
          offset={1}
          style={{
            stopColor: "#FF4FC2",
          }}
        />
      </linearGradient>
      <polygon
        clipPath="url(#SVGID_8_)"
        fill="url(#SVGID_9_)"
        points="-61.142,141.434 -106.546,44.063 -23.709,5.437 21.693,102.805  "
      />
    </g>
    <g>
      <defs>
        <path
          id="SVGID_10_"
          d="M-31.702,119.569H-64.19c6.496,3.362,13.809,5.183,21.551,5.183c26.794,0,48.52-21.727,48.52-48.519 c0-12.409-4.662-23.723-12.326-32.305L-31.702,119.569z"
        />
      </defs>
      <clipPath id="SVGID_11_">
        <use xlinkHref="#SVGID_10_" overflow="visible" />
      </clipPath>
      <linearGradient
        id="SVGID_12_"
        gradientUnits="userSpaceOnUse"
        x1={-582.167}
        y1={479.1563}
        x2={-581.7278}
        y2={479.1563}
        gradientTransform="matrix(-60.1686 -129.032 -129.032 60.1686 26784.3008 -103831.8906)"
      >
        <stop
          offset={0}
          style={{
            stopColor: "#FFCE2A",
          }}
        />
        <stop
          offset={0.9892}
          style={{
            stopColor: "#FF4FC2",
          }}
        />
        <stop
          offset={1}
          style={{
            stopColor: "#FF4FC2",
          }}
        />
      </linearGradient>
      <polygon
        clipPath="url(#SVGID_11_)"
        fill="url(#SVGID_12_)"
        points="-51.674,151.59 -95.146,58.366 -6.636,17.091 36.838,110.313  "
      />
    </g>
    <text
      transform="matrix(1 0 0 1 4.3828 104.7803)"
      fontFamily="'BebasNeueRegular'"
      fontSize={84.8359}
    >
      {"ATDOOR"}
    </text>
    <g>
      <defs>
        <path
          id="SVGID_13_"
          d="M-68.563,8.037c0,4.12,1.569,7.873,4.144,10.696l8.435-26.226C-63.169-5.975-68.563,0.4-68.563,8.037"
        />
      </defs>
      <clipPath id="SVGID_14_">
        <use xlinkHref="#SVGID_13_" overflow="visible" />
      </clipPath>
      <linearGradient
        id="SVGID_15_"
        gradientUnits="userSpaceOnUse"
        x1={-581.9487}
        y1={478.7314}
        x2={-581.8051}
        y2={478.7314}
        gradientTransform="matrix(-60.6391 -130.041 -130.041 60.6391 26908.2539 -104691.5)"
      >
        <stop
          offset={0}
          style={{
            stopColor: "#FFCE2A",
          }}
        />
        <stop
          offset={0.9892}
          style={{
            stopColor: "#FF4FC2",
          }}
        />
        <stop
          offset={1}
          style={{
            stopColor: "#FF4FC2",
          }}
        />
      </linearGradient>
      <polygon
        clipPath="url(#SVGID_14_)"
        fill="url(#SVGID_15_)"
        points="-66.317,23.552 -78.608,-2.809 -58.231,-12.311  -45.938,14.049  "
      />
    </g>
    <g>
      <defs>
        <polygon
          id="SVGID_16_"
          points="-56.184,2.17 -52.359,2.17 -54.16,-4.121  "
        />
      </defs>
      <clipPath id="SVGID_17_">
        <use xlinkHref="#SVGID_16_" overflow="visible" />
      </clipPath>
      <linearGradient
        id="SVGID_18_"
        gradientUnits="userSpaceOnUse"
        x1={-580.2754}
        y1={483.8809}
        x2={-580.1318}
        y2={483.8809}
        gradientTransform="matrix(-19.0129 -40.7732 -40.7732 19.0129 8643.9004 -32857.3125)"
      >
        <stop
          offset={0}
          style={{
            stopColor: "#FFCE2A",
          }}
        />
        <stop
          offset={0.9892}
          style={{
            stopColor: "#FF4FC2",
          }}
        />
        <stop
          offset={1}
          style={{
            stopColor: "#FF4FC2",
          }}
        />
      </linearGradient>
      <polygon
        clipPath="url(#SVGID_17_)"
        fill="url(#SVGID_18_)"
        points="-55.501,3.635 -58.594,-2.998 -53.042,-5.586 -49.949,1.046  "
      />
    </g>
    <g>
      <defs>
        <path
          id="SVGID_19_"
          d="M-48.161,6.574h-2.936l-0.474-1.651h-5.499l-5.076,15.821c0.657,0.489,1.352,0.901,2.081,1.285 l4.531-13.437h5.276l-0.872,2.753h-2.399l-2.727,8.076h4.966l8.196-24.092c-2.571-1.957-5.757-3.12-9.219-3.2L-48.161,6.574z"
        />
      </defs>
      <clipPath id="SVGID_20_">
        <use xlinkHref="#SVGID_19_" overflow="visible" />
      </clipPath>
      <linearGradient
        id="SVGID_21_"
        gradientUnits="userSpaceOnUse"
        x1={-581.9858}
        y1={478.582}
        x2={-581.8422}
        y2={478.582}
        gradientTransform="matrix(-74.0379 -158.7747 -158.7747 74.0379 32849.9961 -127820.0078)"
      >
        <stop
          offset={0}
          style={{
            stopColor: "#FFCE2A",
          }}
        />
        <stop
          offset={0.9892}
          style={{
            stopColor: "#FF4FC2",
          }}
        />
        <stop
          offset={1}
          style={{
            stopColor: "#FF4FC2",
          }}
        />
      </linearGradient>
      <polygon
        clipPath="url(#SVGID_20_)"
        fill="url(#SVGID_21_)"
        points="-58.742,29.328 -73.598,-2.531 -46.495,-15.168  -31.639,16.689  "
      />
    </g>
    <g>
      <defs>
        <path
          id="SVGID_22_"
          d="M-49.109,22.175h-10.63c2.125,1.1,4.518,1.695,7.051,1.695c8.767,0,15.875-7.108,15.875-15.875 c0-4.06-1.525-7.762-4.033-10.57L-49.109,22.175z"
        />
      </defs>
      <clipPath id="SVGID_23_">
        <use xlinkHref="#SVGID_22_" overflow="visible" />
      </clipPath>
      <linearGradient
        id="SVGID_24_"
        gradientUnits="userSpaceOnUse"
        x1={-581.4736}
        y1={479.0586}
        x2={-581.33}
        y2={479.0586}
        gradientTransform="matrix(-60.1686 -129.032 -129.032 60.1686 26784.3008 -103831.8906)"
      >
        <stop
          offset={0}
          style={{
            stopColor: "#FFCE2A",
          }}
        />
        <stop
          offset={0.9892}
          style={{
            stopColor: "#FF4FC2",
          }}
        />
        <stop
          offset={1}
          style={{
            stopColor: "#FF4FC2",
          }}
        />
      </linearGradient>
      <polygon
        clipPath="url(#SVGID_23_)"
        fill="url(#SVGID_24_)"
        points="-55.644,32.651 -69.868,2.149 -40.908,-11.355  -26.684,19.146  "
      />
    </g>
    <g>
      <defs>
        <path
          id="SVGID_25_"
          d="M-30.807,8.028c0,3.115,1.186,5.952,3.132,8.085l6.376-19.824C-26.73-2.563-30.807,2.256-30.807,8.028"
        />
      </defs>
      <clipPath id="SVGID_26_">
        <use xlinkHref="#SVGID_25_" overflow="visible" />
      </clipPath>
      <linearGradient
        id="SVGID_27_"
        gradientUnits="userSpaceOnUse"
        x1={-582.0405}
        y1={478.5039}
        x2={-581.9323}
        y2={478.5039}
        gradientTransform="matrix(-60.6391 -130.041 -130.041 60.6391 26908.2539 -104691.5)"
      >
        <stop
          offset={0}
          style={{
            stopColor: "#FFCE2A",
          }}
        />
        <stop
          offset={0.9892}
          style={{
            stopColor: "#FF4FC2",
          }}
        />
        <stop
          offset={1}
          style={{
            stopColor: "#FF4FC2",
          }}
        />
      </linearGradient>
      <polygon
        clipPath="url(#SVGID_26_)"
        fill="url(#SVGID_27_)"
        points="-29.109,19.756 -38.4,-0.17 -22.997,-7.353 -13.705,12.573  "
      />
    </g>
    <g>
      <defs>
        <polygon
          id="SVGID_28_"
          points="-21.45,3.594 -18.559,3.594 -19.92,-1.162  "
        />
      </defs>
      <clipPath id="SVGID_29_">
        <use xlinkHref="#SVGID_28_" overflow="visible" />
      </clipPath>
      <linearGradient
        id="SVGID_30_"
        gradientUnits="userSpaceOnUse"
        x1={-580.6216}
        y1={483.2109}
        x2={-580.5129}
        y2={483.2109}
        gradientTransform="matrix(-19.0129 -40.7732 -40.7732 19.0129 8643.9004 -32857.3125)"
      >
        <stop
          offset={0}
          style={{
            stopColor: "#FFCE2A",
          }}
        />
        <stop
          offset={0.9892}
          style={{
            stopColor: "#FF4FC2",
          }}
        />
        <stop
          offset={1}
          style={{
            stopColor: "#FF4FC2",
          }}
        />
      </linearGradient>
      <polygon
        clipPath="url(#SVGID_29_)"
        fill="url(#SVGID_30_)"
        points="-20.934,4.701 -23.271,-0.313 -19.075,-2.269 -16.737,2.744  "
      />
    </g>
    <g>
      <defs>
        <path
          id="SVGID_31_"
          d="M-15.385,6.923h-2.219l-0.358-1.249h-4.156l-3.837,11.959c0.497,0.37,1.022,0.681,1.573,0.972 l3.425-10.157h3.988l-0.659,2.081h-1.813l-2.061,6.105h3.754l6.195-18.211c-1.944-1.479-4.352-2.358-6.969-2.419L-15.385,6.923z"
        />
      </defs>
      <clipPath id="SVGID_32_">
        <use xlinkHref="#SVGID_31_" overflow="visible" />
      </clipPath>
      <linearGradient
        id="SVGID_33_"
        gradientUnits="userSpaceOnUse"
        x1={-582.0518}
        y1={478.4072}
        x2={-581.9431}
        y2={478.4072}
        gradientTransform="matrix(-74.0379 -158.7747 -158.7747 74.0379 32849.9961 -127820.0078)"
      >
        <stop
          offset={0}
          style={{
            stopColor: "#FFCE2A",
          }}
        />
        <stop
          offset={0.9892}
          style={{
            stopColor: "#FF4FC2",
          }}
        />
        <stop
          offset={1}
          style={{
            stopColor: "#FF4FC2",
          }}
        />
      </linearGradient>
      <polygon
        clipPath="url(#SVGID_32_)"
        fill="url(#SVGID_33_)"
        points="-23.383,24.123 -34.613,0.04 -14.125,-9.513 -2.896,14.569  "
      />
    </g>
    <g>
      <defs>
        <path
          id="SVGID_34_"
          d="M-16.103,18.715h-8.035c1.606,0.831,3.415,1.281,5.33,1.281c6.627,0,12-5.373,12-12 c0-3.069-1.153-5.867-3.049-7.99L-16.103,18.715z"
        />
      </defs>
      <clipPath id="SVGID_35_">
        <use xlinkHref="#SVGID_34_" overflow="visible" />
      </clipPath>
      <linearGradient
        id="SVGID_36_"
        gradientUnits="userSpaceOnUse"
        x1={-581.5469}
        y1={478.8486}
        x2={-581.4383}
        y2={478.8486}
        gradientTransform="matrix(-60.1686 -129.032 -129.032 60.1686 26784.3008 -103831.8906)"
      >
        <stop
          offset={0}
          style={{
            stopColor: "#FFCE2A",
          }}
        />
        <stop
          offset={0.9892}
          style={{
            stopColor: "#FF4FC2",
          }}
        />
        <stop
          offset={1}
          style={{
            stopColor: "#FF4FC2",
          }}
        />
      </linearGradient>
      <polygon
        clipPath="url(#SVGID_35_)"
        fill="url(#SVGID_36_)"
        points="-21.042,26.635 -31.793,3.578 -9.902,-6.63 0.849,16.425  "
      />
    </g>
    <g>
      <defs>
        <path
          id="SVGID_37_"
          d="M0,8.019c0,2.076,0.791,3.968,2.088,5.39L6.339,0.193C2.718,0.958,0,4.171,0,8.019"
        />
      </defs>
      <clipPath id="SVGID_38_">
        <use xlinkHref="#SVGID_37_" overflow="visible" />
      </clipPath>
      <linearGradient
        id="SVGID_39_"
        gradientUnits="userSpaceOnUse"
        x1={-582.1108}
        y1={478.3223}
        x2={-582.0385}
        y2={478.3223}
        gradientTransform="matrix(-60.6391 -130.041 -130.041 60.6391 26908.2539 -104691.5)"
      >
        <stop
          offset={0}
          style={{
            stopColor: "#FFCE2A",
          }}
        />
        <stop
          offset={0.9892}
          style={{
            stopColor: "#FF4FC2",
          }}
        />
        <stop
          offset={1}
          style={{
            stopColor: "#FF4FC2",
          }}
        />
      </linearGradient>
      <polygon
        clipPath="url(#SVGID_38_)"
        fill="url(#SVGID_39_)"
        points="1.132,15.837 -5.062,2.554 5.207,-2.235 11.402,11.048  "
      />
    </g>
    <g>
      <defs>
        <polygon
          id="SVGID_40_"
          points="6.238,5.063 8.166,5.063 7.258,1.892  "
        />
      </defs>
      <clipPath id="SVGID_41_">
        <use xlinkHref="#SVGID_40_" overflow="visible" />
      </clipPath>
      <linearGradient
        id="SVGID_42_"
        gradientUnits="userSpaceOnUse"
        x1={-580.9023}
        y1={482.6836}
        x2={-580.8299}
        y2={482.6836}
        gradientTransform="matrix(-19.0129 -40.7732 -40.7732 19.0129 8643.9004 -32857.3125)"
      >
        <stop
          offset={0}
          style={{
            stopColor: "#FFCE2A",
          }}
        />
        <stop
          offset={0.9892}
          style={{
            stopColor: "#FF4FC2",
          }}
        />
        <stop
          offset={1}
          style={{
            stopColor: "#FF4FC2",
          }}
        />
      </linearGradient>
      <polygon
        clipPath="url(#SVGID_41_)"
        fill="url(#SVGID_42_)"
        points="6.583,5.801 5.024,2.458 7.821,1.154 9.38,4.496  "
      />
    </g>
    <g>
      <defs>
        <path
          id="SVGID_43_"
          d="M10.281,7.282H8.802L8.563,6.45H5.792l-2.558,7.973c0.331,0.247,0.681,0.454,1.049,0.647l2.283-6.771 h2.659L8.787,9.687H7.577l-1.374,4.07h2.502l4.13-12.142C11.541,0.629,9.935,0.043,8.19,0.003L10.281,7.282z"
        />
      </defs>
      <clipPath id="SVGID_44_">
        <use xlinkHref="#SVGID_43_" overflow="visible" />
      </clipPath>
      <linearGradient
        id="SVGID_45_"
        gradientUnits="userSpaceOnUse"
        x1={-582.1011}
        y1={478.2686}
        x2={-582.0287}
        y2={478.2686}
        gradientTransform="matrix(-74.0379 -158.7747 -158.7747 74.0379 32849.9961 -127820.0078)"
      >
        <stop
          offset={0}
          style={{
            stopColor: "#FFCE2A",
          }}
        />
        <stop
          offset={0.9892}
          style={{
            stopColor: "#FF4FC2",
          }}
        />
        <stop
          offset={1}
          style={{
            stopColor: "#FF4FC2",
          }}
        />
      </linearGradient>
      <polygon
        clipPath="url(#SVGID_44_)"
        fill="url(#SVGID_45_)"
        points="4.949,18.749 -2.537,2.693 11.121,-3.675 18.607,12.379  "
      />
    </g>
    <g>
      <defs>
        <path
          id="SVGID_46_"
          d="M9.803,15.144H4.447C5.518,15.698,6.723,15.998,8,15.998c4.418,0,8-3.582,8-8 c0-2.046-0.768-3.912-2.033-5.327L9.803,15.144z"
        />
      </defs>
      <clipPath id="SVGID_47_">
        <use xlinkHref="#SVGID_46_" overflow="visible" />
      </clipPath>
      <linearGradient
        id="SVGID_48_"
        gradientUnits="userSpaceOnUse"
        x1={-581.5986}
        y1={478.6826}
        x2={-581.5262}
        y2={478.6826}
        gradientTransform="matrix(-60.1686 -129.032 -129.032 60.1686 26784.3008 -103831.8906)"
      >
        <stop
          offset={0}
          style={{
            stopColor: "#FFCE2A",
          }}
        />
        <stop
          offset={0.9892}
          style={{
            stopColor: "#FF4FC2",
          }}
        />
        <stop
          offset={1}
          style={{
            stopColor: "#FF4FC2",
          }}
        />
      </linearGradient>
      <polygon
        clipPath="url(#SVGID_47_)"
        fill="url(#SVGID_48_)"
        points="6.51,20.423 -0.658,5.052 13.936,-1.753 21.104,13.617  "
      />
    </g>
  </svg>
);
export default LogoIcon;
