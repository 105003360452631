// import "./App.css";
import Header from "./Header";
import Banner from "./Banner";
import { img } from "./config/data";
import Footer from "./Footer";
// import Team from "./Team";
// import PricingTable from "./PricingTable";
// import AgencyStatus from "./AgencyStatus";
import Contact from "./contact";
// import Testimonial from "./testimonial";
import About from "./view/about";

function App() {
  return (
    <div className="App">
      <div id="wrapper">
        <div id="content">
          <Header />
          <main
            data-spy="scroll"
            data-target="#navbar-example2"
            data-offset="0"
          >
            <Banner />

            <section className="abo_company">
              <div className="container">
                <div className="row">
                  <div className="col-lg-4 emo mb-4 mb-lg-0">
                    <div className="gq_item bg-blue">
                      <span className="d-block c-white font-s-16">{}</span>
                      <div className="title_sections">
                        <img
                          alt="img"
                          className="mb-3"
                          // src="https://picsum.photos/200"src="../public/assets/img/gif/waving_hand.gif"
                          src={img.waving_hand_gif}
                          width="60"
                        />
                        <h2 className="c-white">
                          Placement and Training – P&T
                        </h2>
                        <p className="c-white">
                          We provide practical approach towards training the
                          students to gain real-time exposure on competitive
                          technologies.Trainings are offered by employees from
                          MNCs to give real-time corporate exposure.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4 emo mb-4 mb-lg-0">
                    <div className="gq_item ill_item">
                      <span className="d-block c-dark font-s-16">{}</span>
                      <img
                        alt="img"
                        className="img-fluid ill_sec"
                        style={{ margin: "2rem" }}
                        // src="https://picsum.photos/200"src="../public/assets/img/agency/Security.png"
                        src={img.security}
                      />
                      <div className="title_sections">
                        <h2 className="c-dark">
                          Human Resource Outsourcing - HRO
                        </h2>
                        <p className="c-gray">
                          Clients are in need of candidates, we do provide
                          freshers and experienced ones allowing the Clients to
                          focus of developing the organization.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4 emo">
                    <div className="gq_item ill_item">
                      <span className="d-block c-dark font-s-16">{}</span>
                      <img
                        alt="img"
                        className="img-fluid ill_sec"
                        style={{ margin: "2rem" }}
                        // src="https://picsum.photos/200"src="../public/assets/img/agency/Update.png"
                        src={img.update}
                      />
                      <div className="title_sections">
                        <h2 className="c-dark">Web Dev and Hosting</h2>
                        <p className="c-gray">
                          You are looking for a website design, enhancing your
                          existing site or design website for your business, our
                          experts can help on it.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>

            {/* <Testimonial /> */}
            <About />
            {/* <Team /> */}
            {/* <AgencyStatus /> */}
            {/* <PricingTable /> */}
            <Contact />
          </main>
        </div>

        <Footer />
        <div className="prgoress_indicator">
          <svg
            className="progress-circle svg-content"
            width="100%"
            height="100%"
            viewBox="-1 -1 102 102"
          >
            <path d="M50,1 a49,49 0 0,1 0,98 a49,49 0 0,1 0,-98" />
          </svg>
        </div>

        <div
          aria-live="polite"
          aria-atomic="true"
          className="d-flex justify-content-center align-items-center"
        >
          <div
            className="toast toast_demo"
            id="myTost"
            role="alert"
            aria-live="assertive"
            aria-atomic="true"
            data-animation="true"
            data-autohide="false"
          >
            <div className="toast-body">
              <button
                type="button"
                className="ml-2 mb-1 close"
                data-dismiss="toast"
                aria-label="Close"
              >
                <i className="tio clear"></i>
              </button>
              <h5>Hi there 👋</h5>
              <p>
                We are glad you joined us{" "}
                <a href="https://atdoor.co.in">Join us</a>
              </p>
            </div>
          </div>
        </div>

        <div
          className="modal mdll_video fade"
          id="mdllVideo"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <button
            type="button"
            className="close bbt_close ripple_circle"
            data-dismiss="modal"
            aria-label="Close"
          >
            <i className="tio clear"></i>
          </button>
          <div className="modal-dialog modal-dialog-centered modal-lg">
            <div className="modal-content">
              <div className="modal-body">
                <div className="embed-responsive embed-responsive-16by9">
                  <iframe
                    title="iframe"
                    className="embed-responsive-item"
                    src=""
                    id="video"
                    allowscriptaccess="always"
                    allow="autoplay"
                  ></iframe>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* <section className="loading_overlay">
          <div className="loader_logo">
            <img
              alt="img"
              className="logo"
              src="https://picsum.photos/200"src="../public/assets/img/logo.svg"
            />
          </div>
        </section> */}
      </div>
    </div>
  );
}

export default App;
