import React from "react";
import { navLinks } from "../config";
import LogoIcon from "../assest/icons/logo-icon";
import "../index.css";

function Header() {
  const { home } = navLinks;

  return (
    <>
      <header className="header-nav-center active-blue" id="myNavbar">
        <div className="container">
          <nav className="navbar navbar-expand-lg navbar-light px-sm-0">
            <a
              className="navbar-brand"
              href={home.link}
              style={{
                fontWeight: 500,
                fontSize: "33px",
                fontFamily: "bebas_neue",
              }}
            >
              <LogoIcon height={32} width={32} /> <span>AtDoor</span>
            </a>

            <button
              className="navbar-toggler menu ripplemenu"
              type="button"
              data-toggle="collapse"
              data-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <svg viewBox="0 0 64 48">
                <path d="M19,15 L45,15 C70,15 58,-2 49.0177126,7 L19,37"></path>
                <path d="M19,24 L45,24 C61.2371586,24 57,49 41,33 L32,24"></path>
                <path d="M45,33 L19,33 C-8,33 6,-2 22,14 L45,37"></path>
              </svg>
            </button>

            <div
              className="collapse navbar-collapse"
              id="navbarSupportedContent"
            >
              <ul className="navbar-nav ml-auto nav-pills">
                {/* <li className="nav-item">
                  <a className="nav-link" href={navLinks.about.link}>
                    About
                  </a>
                </li> */}
                {/* <li className="nav-item">
                  <a className="nav-link" href={navLinks.affiliates.link}>
                    Affiliates
                  </a>
                </li> */}
                {/* <li className="nav-item">
                  <a className="nav-link" href={navLinks.careers.link}>
                    Careers
                  </a>
                </li> */}
              </ul>
              {/* <div className="nav_account btn_demo2">
                <a
                  href={navLinks.contact.link}
                  className="btn btn_sm_primary effect-letter rounded-8"
                >
                  Contact
                </a>
              </div> */}
            </div>
          </nav>
        </div>
      </header>
    </>
  );
}

export default Header;
