import React from "react";
import {
  contactDetails,
  fullAddress,
  social,
  supportType,
} from "../config/data";

const contact = () => {
  const { mobile, email, countryCode } = contactDetails;
  const { doorNo, streetName, city, state, pincode, country } = fullAddress;
  const { facebook, twitter, github } = social;

  return (
    <div id="wrapper">
      <div id="content">
        <main data-spy="scroll" data-target="#navbar-example2" data-offset="0">
          <section className="section_contact_five contact_six margin-t-5 padding-t-7 margin-b-10">
            <div className="container">
              <div className="row">
                <div className="col-lg-4">
                  <div className="title_sections_inner">
                    <h2>Get in touch</h2>
                  </div>
                  <div className="information_agency d-md-flex">
                    <div className="item_data mr-5">
                      <p>Work Inquiries</p>
                      <a className="tel" href={"tel:" + mobile}>
                        {countryCode} {mobile}
                      </a>
                    </div>
                    <div className="item_data">
                      <p>Work Inquiries</p>
                      <a className="tel" href={"mailto:" + email}>
                        {email}
                      </a>
                    </div>
                  </div>

                  <h3 className="font-s-16 font-w-500 c-gray margin-t-4">
                    HQ {city}, {country}
                  </h3>
                  <p className="font-s-16">
                    {doorNo}, {streetName}, {city}, {state}, {country},{" "}
                    {pincode}
                  </p>

                  <div className="information_agency margin-t-4">
                    <div className="item_data">
                      <p>Availability</p>
                      <div>(Mon-Fri) 9am to 5pm IST</div>
                    </div>
                  </div>

                  <div className="social__media">
                    <a href={facebook}>
                      <i className="tio facebook"></i>
                    </a>
                    <a href={github}>
                      <i className="tio github"></i>
                    </a>
                    <a href={twitter}>
                      <i className="tio twitter"></i>
                    </a>
                  </div>
                </div>
                <div className="col-lg-6 ml-auto">
                  <div className="form_cc_four">
                    <form action="" className="row">
                      <div className="col-md-6">
                        <div className="form-group">
                          <label>Email address</label>
                          <input
                            type="email"
                            className="form-control"
                            placeholder="ex. specialperson@example.com"
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <label>Full Name</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="ex. John Doe"
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <label>Select a Subject</label>
                          <select className="form-control custom-select">
                            {supportType.map((o, index) => (
                              <option key={index}>{o}</option>
                            ))}
                          </select>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <label>
                            Choose a Budget{" "}
                            <span className="font-s-13 c-gray">(USD)</span>
                          </label>
                          <select className="form-control custom-select">
                            <option>Less than 5K</option>
                            <option>5K - 10K</option>
                            <option>Over 10K</option>
                            <option>Please advice</option>
                          </select>
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div className="form-group">
                          <label>Message</label>
                          <textarea
                            className="form-control"
                            rows="7"
                            placeholder="Tell us more about your project} needs, and timeline."
                          ></textarea>
                        </div>
                      </div>

                      <div className="col-12 d-md-flex justify-content-between margin-t-2">
                        <div className="item_upload mb-3 mb-md-0">
                          <div className="upload__file">
                            <input type="file" />
                            <button
                              type="button"
                              className="btn btn_md_primary"
                            >
                              <i className="tio attachment c-red"></i>
                              Attach File
                            </button>
                          </div>
                          <span className="my-auto">Maximum size: 12 MB</span>
                        </div>
                        <a
                          href="/quote"
                          className="btn btn_md_primary bg-red rounded-8 c-white h-fit-content"
                        >
                          Get a Quote
                        </a>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </main>
      </div>

      <div className="prgoress_indicator">
        <svg
          className="progress-circle svg-content"
          width="100%"
          height="100%"
          viewBox="-1 -1 102 102"
        >
          <path d="M50,1 a49,49 0 0,1 0,98 a49,49 0 0,1 0,-98" />
        </svg>
      </div>
    </div>
  );
};

export default contact;
