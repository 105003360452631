import React from "react";
import { fullAddress, social, quote1, contactDetails } from "../config/data";
import LogoIcon from "../assest/icons/logo-icon";

export default function Footer() {
  return (
    <>
      <footer className="defalut-footer foot_demo3 light margin-t-12 padding-py-8">
        <div className="container">
          <div className="row">
            <div className="col-lg-4 col-md-6 mb-4 mb-lg-0">
              <div className="item_about">
                <a
                  className="logo"
                  href="agency.html"
                  style={{
                    fontWeight: 500,
                    fontSize: "60px",
                    fontFamily: "bebas_neue",
                  }}
                >
                  <LogoIcon height={55} width={55} /> <span>AtDoor</span>
                </a>
                <p>{quote1}</p>
                <div className="address">
                  <span>
                    {fullAddress.doorNo}, {fullAddress.streetName}
                    <br />
                    {fullAddress.city},<br />
                    {fullAddress.state}, <br />
                    {fullAddress.pincode},<br />
                    {fullAddress.country}
                  </span>
                  <span>
                    Call us:{" "}
                    <a href={"tel:" + contactDetails.mobile}>
                      {contactDetails.mobile}
                    </a>
                  </span>
                </div>
              </div>
            </div>
            <div className="col-6 col-md-6 col-lg-2">
              <div className="item_links">
                <h4>Social</h4>
                <a className="nav-link" href={social.blog}>
                  Blog
                </a>
                <a className="nav-link" href={social.facebook}>
                  Facebook
                </a>
                <a className="nav-link" href={social.twitter}>
                  Twitter
                </a>
                <a className="nav-link" href={social.instagram}>
                  Instagram
                </a>
              </div>
            </div>
            <div className="col-6 col-md-6 col-lg-2">
              <div className="item_links">
                <h4>Company</h4>
                <a className="nav-link" href={social.home}>
                  About
                </a>
                <a className="nav-link" href={social.home}>
                  Affiliates
                </a>
                <a className="nav-link" href={social.home}>
                  Careers
                </a>
                <a className="nav-link" href={social.home}>
                  Legal & Privacy
                </a>
              </div>
            </div>
            <div className="col-md-6 col-lg-4 mt-4 mt-lg-0">
              <div className="item_subscribe">
                <h4>Subscribe</h4>
                <p>
                  Subscribe to get the latest
                  <br />
                  news form us
                </p>
                <form className="form-row">
                  <div className="col-md-11 form-group subscribebtn">
                    <div className="item_input">
                      <input
                        type="email"
                        className="form-control rounded-8"
                        id="exampleInputEmail1"
                        placeholder="Enter email address"
                        aria-describedby="emailHelp"
                      />
                      <button
                        type="button"
                        className="btn ripple_circle scale rounded-8 btn_subscribe"
                      >
                        <i className="tio send"></i>
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
          {/* <div className="row">
              <div className="col-md-6 margin-t-1">
                <select data-menu>
                  <option defaultValue="French">French</option>
                  <option defaultValue="English" selected>
                    English
                  </option>
                  <option defaultValue="Arabic">Arabic</option>
                  <option defaultValue="Russian">Russian</option>
                </select>
              </div>
            </div> */}
          <div className="col-12 text-center padding-t-4">
            <div className="copyright">
              <span>
                © 2016
                <a href={social.home} rel="noreferrer" target="_blank">
                  {" "}
                  AtDoor.{" "}
                </a>
                All Right Reseved
              </span>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
}
