import LogoIcon from "../assest/icons";

const favicon = {
  favicon: <LogoIcon height={16} width={16} />,
};

const img = {
  placeHolderAPI: "https://picsum.photos/200",
  girl: "https://res.cloudinary.com/dc3dylylv/image/upload/v1646640460/AtDoor%20Website%20Assests/agency/girl_rogwi7.svg",
  waving_hand_gif:
    "https://res.cloudinary.com/dc3dylylv/image/upload/v1646640404/AtDoor%20Website%20Assests/gif/waving_hand_rw5mv1.gif",
  security:
    "https://res.cloudinary.com/dc3dylylv/image/upload/v1646640460/AtDoor%20Website%20Assests/agency/Security_paal8i.png",
  update:
    "https://res.cloudinary.com/dc3dylylv/image/upload/v1646640461/AtDoor%20Website%20Assests/agency/Update_yhtzqp.png",
  logoExp:
    "https://res.cloudinary.com/dc3dylylv/image/upload/v1646640436/AtDoor%20Website%20Assests/logos/xperience_ubrfrx.svg",
  emp: "https://res.cloudinary.com/dc3dylylv/image/upload/v1646670317/AtDoor%20Website%20Assests/icons/Employee_dbysnq.svg",
  thousandSixandThousand:
    "https://res.cloudinary.com/dc3dylylv/image/upload/v1646640459/AtDoor%20Website%20Assests/agency/78970654_hp9dbk.png",
  recentProject:
    "https://res.cloudinary.com/dc3dylylv/image/upload/v1646670894/AtDoor%20Website%20Assests/agency/recent-project-unsplash_asyiwv.jpg",
  mobApp:
    "https://res.cloudinary.com/dc3dylylv/image/upload/v1646671073/AtDoor%20Website%20Assests/agency/behnam-norouzi-lmCajireIn8-unsplash_wydepv.jpg",
  newTechVideo:
    "https://res.cloudinary.com/dc3dylylv/video/upload/v1646671552/AtDoor%20Website%20Assests/agency/pexels-michelangelo-buonarroti-8764794_sakfhs.mp4",
  secondImg:
    "https://res.cloudinary.com/dc3dylylv/image/upload/v1646671794/AtDoor%20Website%20Assests/agency/mars-IgUR1iX0mqM-unsplash_xnjiin.jpg",
  male: "https://res.cloudinary.com/dc3dylylv/image/upload/v1646675045/AtDoor%20Website%20Assests/malte-helmhold-mQmuv-3jAOc-unsplash_x0duld.jpg",
  female:
    "https://res.cloudinary.com/dc3dylylv/image/upload/v1646675044/AtDoor%20Website%20Assests/thisisengineering-raeng-hOCYuLmTTnY-unsplash_bac4vh.jpg",
  followers:
    "https://res.cloudinary.com/dc3dylylv/image/upload/v1646640406/AtDoor%20Website%20Assests/icons/1f469_x7uu29.png",
  heart:
    "https://res.cloudinary.com/dc3dylylv/image/upload/v1646640406/AtDoor%20Website%20Assests/icons/2665_w51wlg.png",
  design:
    "https://res.cloudinary.com/dc3dylylv/image/upload/v1646640406/AtDoor%20Website%20Assests/icons/1f58c_y44kvu.png",
  discuss:
    "https://res.cloudinary.com/dc3dylylv/image/upload/v1646640406/AtDoor%20Website%20Assests/icons/1f647-2640_p3kjav.png",
  getInTouch:
    "https://res.cloudinary.com/dc3dylylv/image/upload/v1646640406/AtDoor%20Website%20Assests/icons/1f607_v0mjog.png",
};

const fullAddress = {
  doorNo: "515",
  address: "",
  streetName: "Sallivan Street",
  city: "Coimbatore",
  state: "Tamil Nadu",
  pincode: "641 001",
  country: "India",
};

const contactDetails = {
  mobile: "9842393234",
  email: "info@atdoor.co.in",
  countryCode: "+91",
};

const teamData = [
  {
    name: "Bala Surendaran",
    image: "https://i.pravatar.cc/300?img=2",
    position: "UI & UX Developer",
    social: [
      {
        name: "Facebook",
        link: "https://facebook.com/balasurendaran",
      },
      {
        name: "Twitter",
        link: "https://twitter.com/balasurendaran",
      },
      {
        name: "Github",
        link: "https://github.com/balasurendaran",
      },
    ],
  },
  {
    name: "Gowrishankar",
    image: "https://i.pravatar.cc/300?img=3",
    position: "Scrum Master",
    social: [
      {
        name: "Facebook",
        link: "https://facebook.com/balasurendaran",
      },
      {
        name: "Twitter",
        link: "https://twitter.com/balasurendaran",
      },
      {
        name: "Github",
        link: "https://github.com/balasurendaran",
      },
    ],
  },
  {
    name: "Udhaya Krishna Raj",
    image: "https://i.pravatar.cc/300?img=64",
    position: "Software architect",
    social: [
      {
        name: "Facebook",
        link: "https://facebook.com/balasurendaran",
      },
      {
        name: "Twitter",
        link: "https://twitter.com/balasurendaran",
      },
      {
        name: "Github",
        link: "https://github.com/balasurendaran",
      },
    ],
  },
  {
    name: "Jayaram",
    image: "https://i.pravatar.cc/300?img=12",
    position: "Marketing",
    social: [
      {
        name: "Facebook",
        link: "https://facebook.com/balasurendaran",
      },
      {
        name: "Twitter",
        link: "https://twitter.com/balasurendaran",
      },
      {
        name: "Github",
        link: "https://github.com/balasurendaran",
      },
    ],
  },
  {
    name: "Praveen Kumar",
    image: "https://i.pravatar.cc/300?img=7",
    position: "Backend Developer",
    social: [
      {
        name: "Facebook",
        link: "https://facebook.com/balasurendaran",
      },
      {
        name: "Twitter",
        link: "https://twitter.com/balasurendaran",
      },
      {
        name: "Github",
        link: "https://github.com/balasurendaran",
      },
    ],
  },
];

const social = {
  home: "https://www.atdoor.co.in",
  facebook: "https://www.facebook.com/atdoorofficial",
  twitter: "https://twitter.com/atdoorofficial",
  linkedin: "https://www.linkedin.com/company/atdoorofficial/",
  instagram: "https://www.instagram.com/atdoor.official/",
  pinterest: "https://in.pinterest.com/atdoorofficial/",
  youtube: "https://www.youtube.com/channel/UC69JxB7yPBY7wmOxjPkoFsg",
  blog: "https://atdoorofficial.medium.com",
  github: "https://github.com/atdoorofficial",
};

const supportType = [
  "Please Select",
  "Purchase",
  "Support",
  "Technique",
  "Service Request",
];

const quote1 =
  "AtDoor, strives to provide best of our services at affordable cost, and to add immediate value for customers.";

const navLinks = {
  home: { link: "/home", name: "Home" },
  about: { link: "/about", name: "About" },
  affiliates: { link: "/affiliates", name: "Affiliates" },
  careers: { link: "/careers", name: "Careers" },
  legalPrivacy: { link: "/legal", name: "Legal & Privacy" },
  contact: { link: "/contact", name: "Contact" },
  placement: { link: "/placement", name: "Placement" },
  hro: { link: "/hro", name: "Human Resource Outsourcing" },
  webDev: { link: "dev", name: "Development" },
  hosting: { link: "hosting", name: "Hosting" },
};

export {
  favicon,
  img,
  fullAddress,
  teamData,
  social,
  quote1,
  contactDetails,
  navLinks,
  supportType,
};
