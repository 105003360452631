import React from "react";
// import Team from "../../components/Team";
import MobileTeam from "../../assest/icons/mobile-team";

export default function about() {
  return (
    <div id="wrapper">
      <div id="content">
        <main data-spy="scroll" data-target="#navbar-example2" data-offset="0">
          <section className="pt_banner_inner banner_about_two">
            <div className="container">
              <div className="row">
                <div className="col-md-8 col-lg-6">
                  <div className="banner_title_inner">
                    <span className="c-green2 font-w-500">Welcome 🖐</span>
                    <h1>We're excited to help you on your journey!</h1>
                    <p>Leave us a little info, and we’ll be in touch.</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="container-fluid pl-0">
              <div className="row">
                <div className="col-lg-12">
                  <div
                    className="parallax_cover"
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    {/* <AboutTeam /> */}
                    <MobileTeam />
                  </div>
                </div>
              </div>
            </div>

            <div className="container padding-t-6">
              <div className="row">
                <div className="col-lg-6">
                  <p className="font-s-18 c-gray">
                    We believe everyone deserves to have a website or online
                    store. Innovation and simplicity makes us happy: our goal is
                    to remove any technical or financial barriers that can
                    prevent business owners from making their own website.
                  </p>
                </div>
                <div className="col-lg-5 ml-auto">
                  <p className="font-s-18 c-gray">
                    Today, we're proud to empower individuals and small business
                    owners around the world. Everyone deserves a website, and
                    we're excited to see what you create.
                  </p>
                </div>
              </div>
            </div>
          </section>

          <section className="section__counter counter_about padding-t-12">
            <div className="container">
              <div className="row">
                <div className="col-md-6 col-lg-3">
                  <div
                    className="item__number mb-4 mb-lg-0"
                    data-aos="fade-up"
                    data-aos-delay="0"
                  >
                    <div className="icon">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="22"
                        height="13"
                        viewBox="0 0 22 13"
                      >
                        <g
                          id="Group_6702"
                          data-name="Group 6702"
                          transform="translate(-1 -6)"
                        >
                          <path
                            id="Combined-Shape"
                            d="M5,6H19a1,1,0,0,1,1,1V17H4V7A1,1,0,0,1,5,6Z"
                            fill="#0b2238"
                            fillRule="evenodd"
                          />
                          <rect
                            id="Rectangle"
                            width="22"
                            height="1"
                            rx="0.5"
                            transform="translate(1 18)"
                            fill="#0b2238"
                            opacity="0.3"
                          />
                        </g>
                      </svg>
                    </div>

                    <div className="body__content">
                      <h2>
                        <span className="counter">750</span>K
                      </h2>
                      <h3>
                        Web pages created <br />
                        by users
                      </h3>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 col-lg-3">
                  <div
                    className="item__number mb-4 mb-lg-0"
                    data-aos="fade-up"
                    data-aos-delay="100"
                  >
                    <div className="icon">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="18"
                        height="17.328"
                        viewBox="0 0 18 17.328"
                      >
                        <path
                          id="Combined-Shape"
                          d="M3.957,8.415l7.521-4.6a1,1,0,0,1,1.043,0l7.521,4.6A2,2,0,0,1,21,10.122V19a2,2,0,0,1-2,2H5a2,2,0,0,1-2-2V10.122A2,2,0,0,1,3.957,8.415ZM10,13a1,1,0,0,0-1,1v3a1,1,0,0,0,1,1h4a1,1,0,0,0,1-1V14a1,1,0,0,0-1-1Z"
                          transform="translate(-3 -3.672)"
                          fill="#0b2238"
                          fillRule="evenodd"
                        />
                      </svg>
                    </div>
                    <div className="body__content">
                      <h2>
                        <span className="counter">12.5</span>M
                      </h2>
                      <h3>
                        In-house designed <br />
                        templates
                      </h3>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 col-lg-3">
                  <div
                    className="item__number mb-4 mb-lg-0"
                    data-aos="fade-up"
                    data-aos-delay="200"
                  >
                    <div className="icon">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="18"
                        height="18"
                        viewBox="0 0 18 18"
                      >
                        <g
                          id="Group_6703"
                          data-name="Group 6703"
                          transform="translate(-3 -3)"
                        >
                          <path
                            id="Mask"
                            d="M12,11a4,4,0,1,1,4-4A4,4,0,0,1,12,11Z"
                            fill="#0b2238"
                            opacity="0.3"
                          />
                          <path
                            id="Mask-Copy"
                            d="M3,20.2c.388-4.773,4.261-7.2,8.983-7.2C16.771,13,20.7,15.293,21,20.2a.687.687,0,0,1-.751.8H3.728A1.107,1.107,0,0,1,3,20.2Z"
                            fill="#0b2238"
                          />
                        </g>
                      </svg>
                    </div>
                    <div className="body__content">
                      <h2>
                        + <span className="counter">900</span>K
                      </h2>
                      <h3>
                        Customers served <br />
                        around the world
                      </h3>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 col-lg-3">
                  <div
                    className="item__number mb-4 mb-lg-0"
                    data-aos="fade-up"
                    data-aos-delay="300"
                  >
                    <div className="icon">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16.723"
                        height="16.003"
                        viewBox="0 0 16.723 16.003"
                      >
                        <g
                          id="Group_6704"
                          data-name="Group 6704"
                          transform="translate(-3.638 -4.259)"
                        >
                          <path
                            id="Combined-Shape"
                            d="M12,4.259a1,1,0,0,1,.9.558l2.042,4.138,4.566.664a1,1,0,0,1,.554,1.706l-3.3,3.221.78,4.548a1,1,0,0,1-1.451,1.054L12,18Z"
                            fill="#ffce53"
                            fillRule="evenodd"
                            opacity="0.3"
                          />
                          <path
                            id="Combined-Shape-2"
                            data-name="Combined-Shape"
                            d="M12,4.259V18L7.916,20.147a1,1,0,0,1-1.451-1.054l.78-4.548-3.3-3.221a1,1,0,0,1,.554-1.706l4.566-.664L11.1,4.817A1,1,0,0,1,12,4.259Z"
                            fill="#ffce53"
                            fillRule="evenodd"
                          />
                        </g>
                      </svg>
                    </div>
                    <div className="body__content">
                      <h2>
                        4.7 <span className="font-s-30">/ 5</span>
                      </h2>
                      <h3>
                        Star rating by <br />
                        our users
                      </h3>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section className="software_web our_story margin-t-12" id="About">
            <div className="container">
              <div className="row">
                <div className="col-lg-5 margin-t-8">
                  <div className="item__section mb-4 mb-lg-0">
                    <div className="media">
                      <div className="icon_sec">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="23.589"
                          height="20.716"
                          viewBox="0 0 23.589 20.716"
                        >
                          <path
                            id="Combined-Shape"
                            d="M25.56,17.147l.029-.012v5.4a1.178,1.178,0,0,1-2.011.833l-2.7-2.7H5.534A3.534,3.534,0,0,1,2,17.136V6.534A3.534,3.534,0,0,1,5.534,3H22.026A3.534,3.534,0,0,1,25.56,6.534v10.6S25.56,17.143,25.56,17.147ZM6.91,11.9c1.778,2.667,4.1,4.058,6.87,4.058s5.092-1.391,6.87-4.058a1.178,1.178,0,1,0-1.96-1.307c-1.363,2.044-2.971,3.009-4.91,3.009s-3.547-.965-4.91-3.009A1.178,1.178,0,1,0,6.91,11.9Z"
                            transform="translate(-2 -3)"
                            fill="#fff"
                            fillRule="evenodd"
                          />
                        </svg>
                      </div>
                      <div className="media-body">
                        <div className="title_sections mb-0">
                          <div className="before_title">
                            <span className="c-green2">Lets's Go</span>
                          </div>
                          <h2>Our Story</h2>
                          <p>
                            began in 2016. After years in the web hosting
                            industry, we realized that it was near impossible
                            for the average Jane or Joe to create their own
                            website. Traditional web hosting services were
                            simply too complicated, time consuming, and
                            expensive to manage.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-5 mt-4 mt-lg-0 ml-auto">
                  <div className="image_grid">
                    <img
                      className="img-fluid img_one"
                      src="https://picsum.photos/1500/1500"
                      alt=""
                    />
                  </div>
                </div>
                <div className="w-100"></div>
                <div className="col-lg-5 mt-4 mt-lg-0 mx-auto">
                  <div className="image_grid">
                    <div className="before_line">
                      <img
                        className="img-fluid img_two"
                        src="https://picsum.photos/1500/1500"
                        alt=""
                      />
                    </div>
                  </div>
                </div>
                <div className="col-lg-5 mt-4 mt-lg-0 ml-auto">
                  <div className="image_grid">
                    <img
                      className="img-fluid img_three"
                      src="https://picsum.photos/1800/1200"
                      alt=""
                    />
                  </div>
                </div>
              </div>

              <div className="row margin-t-8">
                <div className="col-lg-6">
                  <p className="font-s-18 c-gray">
                    We believe everyone deserves to have a website or online
                    store. Innovation and simplicity makes us happy: our goal is
                    to remove any technical or financial barriers that can
                    prevent business owners from making their own website.
                  </p>
                </div>
                <div className="col-lg-5 ml-auto">
                  <p className="font-s-18 c-gray">
                    Today, we're proud to empower individuals and small business
                    owners around the world. Everyone deserves a website, and
                    we're excited to see what you create.
                  </p>
                </div>
              </div>
            </div>
          </section>

          {/* <Team /> */}

          <section className="integration__logo">
            <div className="container">
              <div className="row justify-content-center text-center">
                <div className="col-md-8 col-lg-5">
                  <div className="title_sections">
                    <div className="before_title">
                      <span className="c-green2">Integration</span>
                    </div>
                    <h2>Seamless integration with other analytics tools</h2>
                    <p>
                      In hac habitasse platea dictumst quisque sagittis purus
                      sit. Tempus imperdiet nulla malesuada .
                    </p>
                    <a
                      href="/"
                      className="btn btn_md_primary margin-t-2 bg-green2 c-white sweep_top sweep_letter rounded-12"
                    >
                      <div className="inside_item">
                        <span data-hover="All Integration">
                          All Integration
                        </span>
                      </div>
                    </a>
                  </div>
                </div>
              </div>
              <div className="block__circle">
                <div
                  className="item_logo"
                  data-aos="fade-up"
                  data-aos-delay="0"
                >
                  <img src="../../assets/img/software/logo/01.svg" alt="" />
                </div>
                <div
                  className="item_logo"
                  data-aos="fade-up"
                  data-aos-delay="100"
                >
                  <img src="../../assets/img/software/logo/kyan.svg" alt="" />
                </div>
                <div
                  className="item_logo"
                  data-aos="fade-up"
                  data-aos-delay="200"
                >
                  <img src="../../assets/img/software/logo/u-mark.svg" alt="" />
                </div>
                <div
                  className="item_logo"
                  data-aos="fade-up"
                  data-aos-delay="300"
                >
                  <img src="../../assets/img/software/logo/earth.svg" alt="" />
                </div>
                <div
                  className="item_logo"
                  data-aos="fade-up"
                  data-aos-delay="400"
                >
                  <img src="../../assets/img/software/logo/02.svg" alt="" />
                </div>
                <div
                  className="item_logo"
                  data-aos="fade-up"
                  data-aos-delay="500"
                >
                  <img src="../../assets/img/software/logo/03.svg" alt="" />
                </div>
                <div
                  className="item_logo"
                  data-aos="fade-up"
                  data-aos-delay="600"
                >
                  <img src="../../assets/img/software/logo/04.svg" alt="" />
                </div>
              </div>
            </div>
          </section>

          <section className="simplecontact_section bg-white padding-py-12 z-index-3">
            <div className="container">
              <div className="row">
                <div className="col-md-6">
                  <div className="title_sections mb-1 mb-sm-auto">
                    <h2>Still need help?</h2>
                    <p>
                      Click on the blue round button at the bottom right corner
                      of this page. You can also email our support team at
                      <a
                        className="c-green2"
                        href="mailto:support@atdoor.co.in"
                      >
                        {" "}
                        support@atdoor.co.in
                      </a>
                      .
                    </p>
                  </div>
                </div>
                <div className="col-md-6 my-auto ml-auto text-sm-right">
                  <button
                    type="button"
                    className="btn mt-3 rounded-12 sweep_top sweep_letter btn_md_primary c-white scale bg-green2"
                  >
                    <div className="inside_item">
                      <span data-hover="Contact Us">Contact Us</span>
                    </div>
                  </button>
                </div>
              </div>
            </div>
          </section>
        </main>
      </div>

      <div
        className="modal mdll_video fade"
        id="mdllVideo"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <button
          type="button"
          className="close bbt_close ripple_circle"
          data-dismiss="modal"
          aria-label="Close"
        >
          <i className="tio clear"></i>
        </button>
        <div className="modal-dialog modal-dialog-centered modal-lg">
          <div className="modal-content">
            <div className="modal-body">
              <div className="embed-responsive embed-responsive-16by9">
                <iframe
                  className="embed-responsive-item"
                  src=""
                  id="video"
                  title="something"
                  allowscriptaccess="always"
                  allow="autoplay"
                ></iframe>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Back to top with progress indicator */}
      <div className="prgoress_indicator">
        <svg
          className="progress-circle svg-content"
          width="100%"
          height="100%"
          viewBox="-1 -1 102 102"
        >
          <path d="M50,1 a49,49 0 0,1 0,98 a49,49 0 0,1 0,-98" />
        </svg>
      </div>
    </div>
  );
}
