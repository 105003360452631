import React from "react";
import { img } from "./../config/data";
import { quote1 } from "./../config/data";

export default function Banner() {
  return (
    <>
      <section className="demo_1 banner_section banner_demo7">
        <div className="container">
          <div className="row">
            <div className="col-md-5 my-auto">
              <div className="banner_title">
                <h1>We Make Digital Beautiful.</h1>
                <p>{quote1}</p>
                <a
                  href="https://www.atdoor.co.in"
                  className="btn btn_md_primary effect-letter rounded-8 bg-blue c-white"
                >
                  Discover
                </a>
                <div className="margin-t-8">
                  <button
                    type="button"
                    className="btn btn_video"
                    data-toggle="modal"
                    data-src="https://www.youtube.com/embed/VvHoHw5AWTk"
                    data-target="#mdllVideo"
                  >
                    <div className="scale rounded-circle play_video">
                      <i className="tio play_outlined"></i>
                    </div>
                    <span className="ml-3 font-s-16 c-dark">Show video</span>
                  </button>
                </div>
              </div>
            </div>
            <div className="col-md-7">
              <img alt="img" className="ill_05" src={img.girl} />
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
